<template>
  <div class="forexlive-technical-analysis-article-slot__wrapper">
    <div class="forexlive-technical-analysis-article-slot__grid-wrapper">
      <a-media
        :image="image"
        :image-aspect-ratio="$options.consts.aspectRatios.flArticle"
        :video="video"
        :video-aspect-ratio="$options.consts.aspectRatios.flArticle"
        :url="generateFlArticleUrl(article)"
        :rounded="true"
        class="forexlive-technical-analysis-article-slot__featured-media"
      />
      <div
        class="forexlive-technical-analysis-article-slot__text-content-wrapper"
      >
        <a-dots-responsive
          :text="topic"
          tag="h4"
          :link-props="{ to: generateFlArticleUrl(article) }"
          :settings="$options.consts.topicSettings"
          class="forexlive-technical-analysis-article-slot__title"
        />
        <a-dots-responsive
          :text="tldr"
          tag="h4"
          :settings="$options.consts.tldrSettings"
          class="forexlive-technical-analysis-article-slot__tldr"
        />
        <a-visibility show :on="[$breakpoint.aboveDesktopXl]">
          <time class="forexlive-technical-analysis-article-slot__timestamp">
            {{ publishDate }}
          </time>
        </a-visibility>
      </div>
    </div>
    <a-visibility hide :on="[$breakpoint.aboveDesktopXl]">
      <time class="forexlive-technical-analysis-article-slot__timestamp">
        {{ publishDate }}
      </time>
    </a-visibility>
  </div>
</template>

<script>
import { prop } from 'ramda'

import { PROP_TYPES, propValidator } from '@/utils/validators'
import mixins from '@/utils/mixins'
import AMedia from 'shared/AMedia'
import { aspectRatios } from 'enums/aspectRatios'
import ADotsResponsive, { configADots } from 'shared/ADotsResponsive'
import {
  FL_ARTICLE_MEDIA_FIELD,
  getFlImageByCategorySlug
} from '@/utils/helpers/flImages'
import scss from '@/utils/scss'

const { breakpoints } = scss

export default {
  name: 'AArticleSlotTechnicalAnalysis',
  mixins: [mixins.urlGenerators],
  components: { ADotsResponsive, AMedia },
  props: {
    article: propValidator([PROP_TYPES.OBJECT])
  },
  consts: {
    aspectRatios,
    topicSettings: {
      [breakpoints.aboveDesktopXl]: configADots(2, 30, true),
      [breakpoints.desktopXl]: configADots(3, 28, true),
      [breakpoints.desktopLg]: configADots(3, 27, true),
      [breakpoints.desktopMd]: configADots(3, 27, true),
      [breakpoints.desktopSm]: configADots(3, 27, true),
      [breakpoints.tablet]: configADots(3, 27, true),
      [breakpoints.mobile]: configADots(3, 30, false)
    },
    tldrSettings: {
      [breakpoints.aboveDesktopXl]: configADots(2, 25, true),
      [breakpoints.desktopXl]: configADots(2, 20, true),
      [breakpoints.desktopLg]: configADots(2, 18, true),
      [breakpoints.desktopMd]: configADots(2, 22, true),
      [breakpoints.desktopSm]: configADots(2, 22, true),
      [breakpoints.tablet]: configADots(2, 22, true),
      [breakpoints.mobile]: configADots(2, 22, false)
    }
  },
  computed: {
    video() {
      return this.article[FL_ARTICLE_MEDIA_FIELD.FEATURED_VIDEO]
    },
    image() {
      const { CategorySlug } = this.article
      return (
        this.article[FL_ARTICLE_MEDIA_FIELD.FEATURED_IMAGE] ||
        this.article[FL_ARTICLE_MEDIA_FIELD.THUMBNAIL_IMAGE] || {
          ...getFlImageByCategorySlug(CategorySlug),
          AltText: 'ForexLive article'
        }
      )
    },
    topic() {
      return prop('Topic', this.article)
    },
    tldr() {
      try {
        const tldr = JSON.parse(this.article.Tldr)
        const tldrWithoutEmptyItems = tldr.filter(v => !!v)

        return tldrWithoutEmptyItems[0] || ''
      } catch (err) {
        this.$errorHandler(err, this, { showMessage: false })
        return ''
      }
    },
    publishDate() {
      return this.$helper.formatDate.toDefault(this.article.PublishedOn)
    }
  }
}
</script>

<style scoped lang="scss">
@import '~assets/scss/local/mixins/grid';

.forexlive-technical-analysis-article-slot__wrapper {
  .forexlive-technical-analysis-article-slot__grid-wrapper {
    $grid-setup: (
      $columnSetup: (
        $above-desktop-xl: repeat(2, minmax(0, 100%)),
        $mobile: minmax(0, 100%)
      ),
      $columnGapSetup: (
        $above-desktop-xl: 20px,
        $desktop-xl: 15px,
        $desktop-lg: 10px
      ),
      $rowGapSetup: (
        $mobile: 15px
      )
    );

    @include gridBuilder($grid-setup);
  }

  .forexlive-technical-analysis-article-slot__title {
    $font-size: (
      $above-desktop-xl: 24px,
      $desktop-xl: 22px,
      $desktop-lg: 21px,
      $desktop-md: 24px,
      $mobile: 22px
    );

    @include setStyleByBreakpoint('font-size', $font-size);

    font-weight: 600;
  }

  .forexlive-technical-analysis-article-slot__tldr {
    $margin-top: (
      $above-desktop-xl: 20px,
      $desktop-xl: 10px,
      $desktop-lg: 7px,
      $desktop-md: 10px
    );
    $font-size: (
      $above-desktop-xl: 16px,
      $desktop-lg: 14px,
      $desktop-md: 16px
    );

    @include setStyleByBreakpoint('margin-top', $margin-top);
    @include setStyleByBreakpoint('font-size', $font-size);
  }

  .forexlive-technical-analysis-article-slot__timestamp {
    $margin-top: (
      $above-desktop-xl: 25px,
      $desktop-xl: 15px
    );
    @include setStyleByBreakpoint('margin-top', $margin-top);

    display: block;
    font-size: 12px;
  }
}
</style>
